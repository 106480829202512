import {
    ANIMATION_DURATION as SOURCE_ANIMATION_DURATION,
    HEIGHT_TRANSITION_SPEED_ON_MOUNT as SOURCE_HEIGHT_TRANSITION_SPEED_ON_MOUNT,
    ACTIVE_SLIDE_PERCENT as SOURCE_ACTIVE_SLIDE_PERCENT,
} from 'SourceComponent/Slider/Slider.config';

//TODO: implement ANIMATION_DURATION
export const ANIMATION_DURATION = SOURCE_ANIMATION_DURATION;

//TODO: implement HEIGHT_TRANSITION_SPEED_ON_MOUNT
export const HEIGHT_TRANSITION_SPEED_ON_MOUNT = SOURCE_HEIGHT_TRANSITION_SPEED_ON_MOUNT;

//TODO: implement ACTIVE_SLIDE_PERCENT
export const ACTIVE_SLIDE_PERCENT = SOURCE_ACTIVE_SLIDE_PERCENT;
