import {
    SliderComponent as SourceSliderComponent,
} from 'SourceComponent/Slider/Slider.component';
import {
    Children,
    createRef,
    MouseEvent,
    PureComponent,
    ReactNode,
    RefObject,
} from 'react';

import ChevronIcon from 'Component/ChevronIcon';
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import Draggable from 'Component/Draggable';
import { DraggableComponentState } from 'Component/Draggable/Draggable.type';
import { ReactElement } from 'Type/Common.type';
import { noopFn } from 'Util/Common';
import CSS from 'Util/CSS';
import { isRtl } from 'Util/CSS/CSS';

import {
    ACTIVE_SLIDE_PERCENT,
    ANIMATION_DURATION,
    HEIGHT_TRANSITION_SPEED_ON_MOUNT,
} from './Slider.config';
import { SliderComponentProps, SliderComponentState } from './Slider.type';

import './Slider.style';

export class SliderComponent extends SourceSliderComponent {
    // renderSliderContent(): ReactElement {
    //     const { activeImage, children, isVertical } = this.props;
    //     const dir = this.getDir();
    //
    //     if (!this.getIsSlider()) {
    //         return children;
    //     }
    //
    //     return (
    //         <Draggable
    //             mix={ { block: 'Slider', elem: 'Wrapper', mods: { isVertical } } }
    //             draggableRef={ this.draggableRef }
    //             onDragStart={ this.handleDragStart }
    //             onDragEnd={ this.handleDragEnd }
    //             onDrag={ this.handleDrag }
    //             onClick={ this.handleClick }
    //             shiftX={ -activeImage * this.getSlideWidth() * dir }
    //             shiftY={ -activeImage * this.getSlideWidth() }
    //         >
    //             { children }
    //         </Draggable>
    //     );
    // }
    //
    // render(): ReactElement {
    //     return this.renderSliderContent();
    // }
};

export default SliderComponent;
